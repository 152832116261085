<template>
  <UHeader :to="localePath('/')">
    <template #logo>
      <template v-if="header?.logo?.dark || header?.logo?.light">
        <Logo />
        <UBadge
          label="Docs"
          variant="subtle"
          class="mb-0.5"
        />
      </template>
    </template>

    <template #right>
      <UContentSearchButton
        :label="''"
        class="lg:hidden"
      />
      <ClientOnly>
        <ColorModeButton />
        <ThemeButton />
      </ClientOnly>
    </template>

    <template #panel-button>
      <UButton
        class="lg:hidden"
        variant="ghost"
        color="gray"
        :aria-label="`${isHeaderDialogOpen ? 'Close' : 'Open'} Menu`"
        :icon="
          isHeaderDialogOpen
            ? 'i-heroicons-x-mark-20-solid'
            : 'i-heroicons-bars-3-20-solid'
        "
        @click="onMenuButtonClick"
      />
    </template>

    <template #panel>
      <UVerticalNavigation
        class="-mx-1 mb-5"
        :links="docsNavigationLinks"
      />

      <UNavigationTree
        v-if="cleanedNavigationFiles[0] && cleanedNavigationFiles[0].children"
        :links="
          mapContentNavigation(
            cleanedNavigationFiles[0].children
              ? cleanedNavigationFiles[0].children
              : []
          )
        "
      />
    </template>
  </UHeader>
</template>
<script setup lang="ts">
import type { NavItem } from '@nuxt/content/dist/runtime/types'

const { header } = useAppConfig()
const localePath = useLocalePath()

const docsNavigation = inject<Ref<NavItem[]>>('docsNavigation', ref([]))
const cleanedNavigationFiles = cleanupContentFilesForNav(docsNavigation.value)
const docsNavigationLinks = inject<
  { label: string; icon: string; to: string }[]
>('docsNavigationLink', [])

const { isHeaderDialogOpen } = useUIState()

function onMenuButtonClick() {
  isHeaderDialogOpen.value = !isHeaderDialogOpen.value

  nextTick(() => {
    const dialogElement = document.querySelector(
      '#headlessui-portal-root div[data-headlessui-state="open"]'
    )
    if (dialogElement) {
      // add attribute data-lenis-prevent to dialog element
      dialogElement.setAttribute('data-lenis-prevent', 'true')
    }
  })
}
</script>
