<template>
  <div>
    <TheDocsNavigation />
    <UMain><slot /></UMain>
    <TheFooter />
    <ClientOnly>
      <LazyUContentSearch
        data-lenis-prevent="true"
        :files="navigationFiles"
        :navigation="docsNavigation"
        :links="docsNavigationLinks"
      />
    </ClientOnly>
  </div>
</template>
<script setup lang="ts">
const docsNavigation = inject('docsNavigation')
const { data: files } = useLazyFetch<any[]>('/api/search.json', {
  default: () => [],
  server: false,
})

const navigationFiles = computed(() => {
  // filter out files which _path starts with /version
  return files.value.filter((file) => !file._path.startsWith('/version'))
})

const localePath = useLocalePath()

console.log('FILES', files)
const docsNavigationLinks = [
  {
    label: 'Home',
    icon: 'i-heroicons-home',
    to: localePath('/'),
  },

  {
    label: 'Pricing',
    to: localePath('/pricing'),
    icon: 'i-heroicons-credit-card',
  },
  {
    label: 'Changelog',
    icon: 'i-heroicons-rocket-launch',
    to: localePath('/changelog'),
  },
]
provide('docsNavigationLink', docsNavigationLinks)
</script>
<style lang="scss">
.prose {
  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--j-text-1);
  }

  & p,
  li,
  span {
    color: var(--j-text-2);
    @apply font-sans text-[16px] leading-normal tracking-body;
  }

  & strong {
    @apply text-text-1;
  }
}
</style>
